import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import styled from 'styled-components';
import { HashLink } from '../components/link';
import hobbyInfraImg from '../images/hobby-infra.png';
import prodInfraImg from '../images/prod-infra.png';
import { StyledImage } from '../components/posts/prod-infra-migration';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hobby Project to Production: Infrastructure Migration`}</h1>
    <p>{`These are my notes on setting up the infrastructure to transform `}<a parentName="p" {...{
        "href": "https://feedwise.net"
      }}>{`FeedWise`}</a>{` from a hobby project to a Production-ready application.`}</p>
    <h2><HashLink id="context" to="/prod-infra-migration#context" mdxType="HashLink">{`Context`}</HashLink></h2>
    <p>{`Here's a bit of context on what I was trying to accomplish.`}</p>
    <p>{`This is what my deployment process looked like at the start of this project:`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <StyledImage src={hobbyInfraImg} alt="Hobby project infrastructure for the FeedWise application" mdxType="StyledImage" />
    </div>
    <p>{`This is the desired process for after the project:`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <StyledImage src={prodInfraImg} alt="Target Production project infrastructure for the FeedWise application" mdxType="StyledImage" />
    </div>
    <p>{`The goal was to move the existing infrastructure from my personal Fly account to the new company account and introduce a new Staging environment.`}</p>
    <h2><HashLink id="staging" to="/prod-infra-migration#staging" mdxType="HashLink">{`Setting up the Staging environment`}</HashLink></h2>
    <p>{`There are some concepts that are not meant for this post. For example, I'm not going to explain why I think introducing a Staging environment is beneficial. And of course there is setup involved with creating a company Fly IO account, connecting a payment method, etc.`}</p>
    <p>{`Setting up the Staging environment involved the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Create the PostgreSQL DB in Fly`}</li>
      <li parentName="ol">{`Deploy the Staging version of the API`}</li>
      <li parentName="ol">{`Configure certs`}</li>
      <li parentName="ol">{`Deploy/configure the Staging version of the client`}</li>
      <li parentName="ol">{`Configure automatic deployments`}</li>
      <li parentName="ol">{`Ensure you can connect to the database (optional but recommended)`}</li>
    </ol>
    <h3><HashLink id="staging-db" to="/prod-infra-migration#staging-db" mdxType="HashLink">{`Create the PostgreSQL DB in Fly`}</HashLink></h3>
    <p>{`Before I created the new DB I needed to find the existing image ref for the current Production DB. It needs to match since this environment should be as close to Production as possible.`}</p>
    <p>{`To find this information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# find the Production database NAME
fly postgres list

# find the image information
fly image show -a stg-postgres-app-name
`}</code></pre>
    <p>{`The values under the `}<inlineCode parentName="p">{`REPOSITORY`}</inlineCode>{` and `}<inlineCode parentName="p">{`TAG`}</inlineCode>{` columns reference the image to use. Legacy Postgres images use the `}<inlineCode parentName="p">{`flyio/postgres`}</inlineCode>{` repository, while new Postgres Flex images use the `}<inlineCode parentName="p">{`flyio/postgres-flex`}</inlineCode>{` repository.`}</p>
    <p>{`For example, in my case `}<inlineCode parentName="p">{`REPOSITORY`}</inlineCode>{` was `}<inlineCode parentName="p">{`flyio/postgres`}</inlineCode>{` and the `}<inlineCode parentName="p">{`TAG`}</inlineCode>{` was `}<inlineCode parentName="p">{`14.6`}</inlineCode>{` so the image ref was `}<inlineCode parentName="p">{`flyio/postgres:14.6`}</inlineCode>{`.`}</p>
    <p>{`This is the command to create the new instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly postgres create --image-ref flyio/postgres:14.6 -n stg-postgres-app-name -o acme-corp-llc
`}</code></pre>
    <p>{`Follow the CLI prompts to configure according to your needs. Be sure to note the connection data that is output from this command. You can use it to connect to the DB in the `}<a parentName="p" {...{
        "href": "#staging-db-connect"
      }}>{`relevant step below`}</a>{`.`}</p>
    <p>{`Ensure the DB is created and in a healthy state by checking your fly dashboard or by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly status -a stg-postgres-app-name
`}</code></pre>
    <h3><HashLink id="staging-api" to="/prod-infra-migration#staging-api" mdxType="HashLink">{`Deploy the Staging version of the API`}</HashLink></h3>
    <p>{`To do this I created a new `}<inlineCode parentName="p">{`fly-stg.toml`}</inlineCode>{` file in my repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markup"
      }}>{`app = 'stg-api-app-name'
primary_region = 'den'
kill_signal = 'SIGTERM'

[build]
  dockerfile = "Dockerfile.fly"

[deploy]
  release_command = '/app/bin/migrate'

[env]
  PHX_HOST = 'stg-api-app-name.fly.dev'
  PORT = '8080'

[http_service]
  internal_port = 8080
  force_https = true
  auto_stop_machines = 'stop'
  auto_start_machines = true
  min_machines_running = 0
  processes = ['app']

  [http_service.concurrency]
    type = 'connections'
    hard_limit = 1000
    soft_limit = 1000

[[vm]]
  memory = '1gb'
  cpu_kind = 'shared'
  cpus = 1

`}</code></pre>
    <p>{`Then launch/deploy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# launch API by follow prompts
fly launch -c fly-stg.toml -o acme-corp-llc

fly secrets set -a stg-api-app-name NAME=VALUE NAME=VALUE ...

fly deploy -c fly-stg.toml -a stg-api-app-name

# attach to the Staging DB
fly postgres attach stg-postgres-app-name --app stg-api-app-name
`}</code></pre>
    <h3><HashLink id="staging-certs" to="/prod-infra-migration#staging-certs" mdxType="HashLink">{`Configure certs`}</HashLink></h3>
    <p>{`I have a custom domain so the next step was to configure certs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly certs add api.staging.domain.io -a stg-api-app-name

# check status
fly certs show api.staging.domain.io -a stg-api-app-name
`}</code></pre>
    <h3><HashLink id="staging-client" to="/prod-infra-migration#staging-client" mdxType="HashLink">{`Deploy/configure the Staging version of the client`}</HashLink></h3>
    <p>{`I use Netlify to deploy the client build artifacts so this step basically involved:`}</p>
    <ol>
      <li parentName="ol">{`creating a new app`}</li>
      <li parentName="ol">{`pointing it at the GitHub repo so it deploys on merge to trunk`}</li>
      <li parentName="ol">{`adding environment config values`}</li>
      <li parentName="ol">{`deploying the app`}</li>
    </ol>
    <p>{`Now it was time to test everything and make sure the app was functional when hitting the Staging URL.`}</p>
    <h3><HashLink id="staging-deploys" to="/prod-infra-migration#staging-deploys" mdxType="HashLink">{`Configure automatic deployments`}</HashLink></h3>
    <p>{`For this I created a `}<inlineCode parentName="p">{`.github/workflows/fly-deploy.yml`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yml"
      }}>{`# See https://fly.io/docs/app-guides/continuous-deployment-with-github-actions/

name: Fly Deploy
on:
  push:
    branches:
      - trunk
jobs:
  deploy:
    name: Deploy app
    runs-on: ubuntu-latest
    concurrency: deploy-group # optional: ensure only one action runs at a time
    steps:
      - uses: actions/checkout@v4
      - uses: superfly/flyctl-actions/setup-flyctl@master
      - run: flyctl deploy -c fly-stg.toml -a stg-api-app-name
        env:
          FLY_API_TOKEN: \${{ secrets.FLY_API_TOKEN }}
`}</code></pre>
    <p>{`Then all I needed to do was add the `}<inlineCode parentName="p">{`FLY_API_TOKEN`}</inlineCode>{` secret via the GitHub UI for my repo and merge this new file to trunk.`}</p>
    <h3><HashLink id="staging-db-connect" to="/prod-infra-migration#staging-db-connect" mdxType="HashLink">{`Ensure you can connect to the database`}</HashLink></h3>
    <p>{`This step is optional but I would recommend it just to confirm that you're able to jack into the private network for the new organization.`}</p>
    <p>{`To connect to a Fly DB you need to `}<a parentName="p" {...{
        "href": "https://fly.io/docs/blueprints/connect-private-network-wireguard/"
      }}>{`install/configure WireGuard`}</a>{`.`}</p>
    <p>{`Once that's all set use the connection string data noted in `}<a parentName="p" {...{
        "href": "#staging-db"
      }}>{`the step above to create the DB`}</a>{` in order to test your connection. I use TablePlus as my DBMS so I confirmed that I could connect to the deployed DB and saved the connection configuration.`}</p>
    <h2><HashLink id="prod" to="/prod-infra-migration#prod" mdxType="HashLink">{`Setting up the Production environment`}</HashLink></h2>
    <p>{`Now that the Staging environment was set up the next step was to get Production up and running.`}</p>
    <p>{`Setting up the Production environment involved the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Create the Production version of the API`}</li>
      <li parentName="ol">{`Create a new PostgreSQL DB in Fly`}</li>
      <li parentName="ol">{`Ensure you can connect to the database`}</li>
      <li parentName="ol">{`Copy the Production data into the new DB`}</li>
      <li parentName="ol">{`Attach the API to the new DB`}</li>
      <li parentName="ol">{`Configure certs`}</li>
      <li parentName="ol">{`Configure the Production version of the client`}</li>
    </ol>
    <h3><HashLink id="prod-api" to="/prod-infra-migration#prod-api" mdxType="HashLink">{`Create the Production version of the API`}</HashLink></h3>
    <p>{`There is `}<a parentName="p" {...{
        "href": "https://fly.io/docs/apps/move-app-org/"
      }}>{`documentation for moving a Fly project`}</a>{` but I ended up just deploying a new instance of the API instead. I went this route because you cannot use this command to move a Postgres DB and I wanted to test the new set up before cutting over.`}</p>
    <p>{`From the documentation linked above:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Fly Postgres:`}</strong>{` You can’t use the `}<inlineCode parentName="p">{`fly apps move`}</inlineCode>{` command for Fly Postgres apps. To move a Postgres app to another organization, create a new Postgres app under the target organization, and then `}<a parentName="p" {...{
          "href": "https://fly.io/docs/postgres/managing/backup-and-restore/#restoring-from-a-snapshot"
        }}>{`restore the data from your current Postgres app volume snapshot`}</a>{`.`}</p>
    </blockquote>
    <h3><HashLink id="prod-db" to="/prod-infra-migration#prod-db" mdxType="HashLink">{`Create a new PostgreSQL DB in Fly`}</HashLink></h3>
    <p>{`At first, I looked into `}<a parentName="p" {...{
        "href": "https://fly.io/docs/postgres/managing/backup-and-restore/#restoring-from-a-snapshot"
      }}>{`creating a DB from an existing snapshot`}</a>{` of the Production DB but Fly does not support restoring from a snapshot across organizations.`}</p>
    <p>{`Given the snapshot provisioning wasn't going to work, I just created a new app for the DB in the new organization.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly postgres create --image-ref flyio/postgres:14.6 -n prod-postgres-app-name -o acme-corp-llc
`}</code></pre>
    <h3><HashLink id="prod-db-connect" to="/prod-infra-migration#prod-db-connect" mdxType="HashLink">{`Ensure you can connect to the database`}</HashLink></h3>
    <p>{`Using the same WireGuard tunnel set up in the above section for the Staging testing, I tested the connection to my new DB instance.`}</p>
    <h3><HashLink id="prod-db-copy" to="/prod-infra-migration#prod-db-copy" mdxType="HashLink">{`Copy the Production data into the new DB`}</HashLink></h3>
    <p>{`Copying the Prodcution data with TablePlus was a 2-step process:`}</p>
    <ol>
      <li parentName="ol">{`backed up my live Production DB from my personal Fly account`}</li>
      <li parentName="ol">{`restored the new Production DB from the new Org using the `}<inlineCode parentName="li">{`.dump`}</inlineCode>{` file backed up from step 1`}</li>
    </ol>
    <h3><HashLink id="prod-db-attach" to="/prod-infra-migration#prod-db-attach" mdxType="HashLink">{`Attach the API to the new DB`}</HashLink></h3>
    <p>{`By default, the secret value with the DB connection string is set when deploying the API for the first time. To attach the new Prod API to the new DB I needed to update that value and attach it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly secrets unset -a prod-api-app-name DATABASE_URL

fly postgres attach prod-postgres-app-name --app prod-api-app-name --database-name <configured-db-name>
`}</code></pre>
    <h3><HashLink id="prod-certs" to="/prod-infra-migration#prod-certs" mdxType="HashLink">{`Configure certs`}</HashLink></h3>
    <p>{`The next step was to set up my Production certs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`fly certs add api.domain.io -a prod-api-app-name

# check status
fly certs show api.domain.io -a prod-api-app-name
`}</code></pre>
    <h3><HashLink id="prod-client" to="/prod-infra-migration#prod-client" mdxType="HashLink">{`Configure the Production version of the client`}</HashLink></h3>
    <p>{`In this case, I was actually switching to a new domain. This worked out for me because I could stand up a new Production API without cutting over the old one in the process and risking downtime.`}</p>
    <p>{`Configuring my Production client was as simple as updating the base API URL environment variable.`}</p>
    <h3><HashLink id="prod-deps" to="/prod-infra-migration#prod-deps" mdxType="HashLink">{`Update 3rd party dependencies`}</HashLink></h3>
    <p>{`Because my API URL was updated, I also needed to update all 3rd party dependencies calling into my system. Things like OAuth flows and web hooks from services just needed to be configured to use the new Production domain.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      